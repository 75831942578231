import { Component, OnInit } from "@angular/core";
import { MenuService } from "../../services/menu.service";
import { Role } from "src/app/models/role";
import { CheckUserAPIResponse } from "src/app/models/http-response";

interface MenuItem {
  icon: string;
  title: string;
  link: string;
  bgClass: string;
  textClass: string;
  visible: boolean;
}

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit {
  constructor(private menuService: MenuService) {}
  roles: Role[] = [];
  greeting = "";
  userName = "";

  menuItems: MenuItem[] = [
    {
      icon: "bi-people",
      title: "Customer Management",
      link: "/app/customers",
      bgClass: "bg-soft-primary",
      textClass: "text-primary",
      visible: false,
    },
    {
      icon: "bi-hospital",
      title: "Provider Management",
      link: "/app/providers",
      bgClass: "bg-soft-success",
      textClass: "text-success",
      visible: false,
    },
    {
      icon: "bi-file-earmark-text",
      title: "Claims Management",
      link: "/app/claims",
      bgClass: "bg-soft-info",
      textClass: "text-info",
      visible: false,
    },
    {
      icon: "bi-pencil-square",
      title: "Underwriting",
      link: "/app/underwriting",
      bgClass: "bg-soft-warning",
      textClass: "text-warning",
      visible: false,
    },
    {
      icon: "bi-file-earmark-text",
      title: "Policy Management",
      link: "/app/policy",
      bgClass: "bg-soft-warning",
      textClass: "text-warning",
      visible: false,
    },
    {
      icon: "bi-shield-check",
      title: "Micro-Insurance",
      link: "/app/micro",
      bgClass: "bg-soft-warning",
      textClass: "text-warning",
      visible: false,
    },
    {
      icon: "bi-key",
      title: "Access89",
      link: "/app/access",
      bgClass: "bg-soft-warning",
      textClass: "text-warning",
      visible: false,
    },
    {
      icon: "bi-heart-pulse",
      title: "mCare",
      link: "/app/mcare",
      bgClass: "bg-soft-warning",
      textClass: "text-warning",
      visible: false,
    },
  ];

  ngOnInit() {
    this.setGreeting();
    this.getUserName();

    this.menuService.checkuser().subscribe((data: CheckUserAPIResponse) => {
      this.roles = JSON.parse(data.roles);
      this.updateMenuVisibility();
    });
  }

  updateMenuVisibility() {
    for (const role of this.roles) {
      switch (role.roleid) {
        case "uor":
        case "ura":
        case "uu":
        case "umu":
        case "upc":
          this.setMenuItemVisibility("Underwriting", true);
          break;
        case "ccp":
        case "cpa":
        case "cch":
        case "cpah":
          this.setMenuItemVisibility("Claims Management", true);
          break;
        case "cmci":
          this.setMenuItemVisibility("Customer Management", true);
          break;
        case "pap":
        case "ppa":
          this.setMenuItemVisibility("Provider Management", true);
          break;
        case "pinv":
        case "pqut":
          this.setMenuItemVisibility("Policy Management", true);
          break;
        case "mis":
        case "mimu":
        case "mimci":
        case "mir":
          this.setMenuItemVisibility("Micro-Insurance", true);
          break;
        case "apc":
          this.setMenuItemVisibility("Access89", true);
          break;
        case "mcarec":
        case "mcarea":
        case "mcarema":
        case "mcarer":
          this.setMenuItemVisibility("mCare", true);
          break;
      }
    }
  }

  setMenuItemVisibility(title: string, visible: boolean) {
    const menuItem = this.menuItems.find((item) => item.title === title);
    if (menuItem) {
      menuItem.visible = visible;
    }
  }

  setGreeting() {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      this.greeting = "Good morning";
    } else if (currentHour < 18) {
      this.greeting = "Good afternoon";
    } else {
      this.greeting = "Good evening";
    }
  }

  getUserName() {
    this.userName = localStorage.getItem("name") || "User";
  }
}

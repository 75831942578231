import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Notifications } from "src/app/models/notifications";
import { MenuService } from "../../services/menu.service";
import { Role } from "src/app/models/role";
import * as moment from "moment";
import { CheckUserAPIResponse } from "src/app/models/http-response";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  constructor(private menuService: MenuService, private router: Router) {}

  notifs: Notifications[] = [];
  notification: Notification = <Notification>{};
  notiNo: number;
  urname: string;
  leave_state = false;
  is_admin = false;
  noNotifs = false;

  roles: Role[] = [];
  viewCustMgt = false;
  viewProviderMgt = false;
  viewClaimsMgt = false;
  viewUnderW = false;
  viewPolicyMgt = false;
  viewMicroIns = false;
  viewAccess = false;
  viewMcare = false;
  loadingNotifs = false;

  mMenu = false;

  ngOnInit() {
    this.notiNo = this.notifs.length;
    this.urname = localStorage.getItem("name");
    this.getNewNotifications();
    /* this.getInfo(); */
    this.checkUser();
  }

  getNewNotifications() {
    this.menuService.getNewNotifs().subscribe((data: Notifications[]) => {
      this.notifs = data;

      if (this.notifs.length === 0) {
        this.noNotifs = true;
      } else {
        this.noNotifs = false;
      }
    });
  }

  timeAgo(dateStr: string): string {
    const now = moment();
    const date = moment(dateStr);
    const diffInMinutes = now.diff(date, "minutes");

    if (diffInMinutes < 60) {
      return `${diffInMinutes} mins ago`;
    } else if (diffInMinutes < 1440) {
      const diffInHours = now.diff(date, "hours");
      return `${diffInHours} hours ago`;
    } else {
      return date.format("YYYY-MM-DD");
    }
  }

  checkUser() {
    this.menuService.checkuser().subscribe((data: CheckUserAPIResponse) => {
      if (data.status === "507") {
        if (data.profile === "Admin") {
          this.is_admin = true;
        } else {
          this.is_admin = false;
        }

        this.roles = JSON.parse(data.roles);
        for (let i = 0; i < this.roles.length; i++) {
          this.sortMenuOptions(this.roles[i]);
        }
      }
    });
  }

  showNotifiDetails(notification: Notification) {
    this.notification = notification;
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("profile");
    this.router.navigateByUrl("/");
  }

  sortMenuOptions(role: Role) {
    switch (role.roleid) {
      case "uor":
        this.viewUnderW = true;
        break;
      case "ura":
        this.viewUnderW = true;
        break;
      case "uu":
        this.viewUnderW = true;
        break;
      case "umu":
        this.viewUnderW = true;
        break;
      case "upc":
        this.viewUnderW = true;
        break;
      case "ccp":
        this.viewClaimsMgt = true;
        break;
      case "cpa":
        this.viewClaimsMgt = true;
        break;
      case "cch":
        this.viewClaimsMgt = true;
        break;
      case "cpah":
        this.viewClaimsMgt = true;
        break;
      case "cmci":
        this.viewCustMgt = true;
        break;
      case "pap":
        this.viewProviderMgt = true;
        break;
      case "ppa":
        this.viewProviderMgt = true;
        break;
      case "pinv":
        this.viewPolicyMgt = true;
        break;
      case "pqut":
        this.viewPolicyMgt = true;
        break;
      case "mis":
        this.viewMicroIns = true;
        break;
      case "mimu":
        this.viewMicroIns = true;
        break;
      case "mimci":
        this.viewMicroIns = true;
        break;
      case "mir":
        this.viewMicroIns = true;
        break;
      case "apc":
        this.viewAccess = true;
        break;
      case "mcarec":
        this.viewMcare = true;
        break;
      case "mcarea":
        this.viewMcare = true;
        break;
      case "mcarema":
        this.viewMcare = true;
        break;
      case "mcarer":
        this.viewMcare = true;
        break;
    }
  }

  toggleMenu() {
    if (this.mMenu === false) {
      this.mMenu = true;
    } else {
      this.mMenu = false;
    }
  }

  markAllAsRead() {
    this.loadingNotifs = true;
    this.menuService.readAllNotif().subscribe(() => {
      this.getNewNotifications();
      this.loadingNotifs = false;
    });
  }
}

<div class="table-responsive">
  <table class="table" *ngIf="allNotifs.length !== 0">
    <thead>
      <tr>
        <th>Title</th>
        <th>Details</th>
        <th>Date</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let info of allNotifs">
        <td>{{info.notificationsubject}} <span class="badge badge-info"
          *ngIf="info.notificationstatus === 'unread'">Unread</span></td>
        <td [innerHTML]="info.notificationbody"></td>
        <td>{{ timeAgo(info.datecreated) }}</td>
        <td *ngIf="info.notificationstatus === 'unread'">
          <button class="btn btn-sm btn-primary" (click)="markAsRead(info.id)"> Mark as read</button>
        </td>
      </tr>
    </tbody>
  </table>
  <h5 class="text-center" *ngIf="allNotifs?.length === 0">You have no notifications</h5>
  <h5 class="text-center" *ngIf="loadAllNotifs">Loading...<i class="bi bi-arrow-repeat spin"></i>
  </h5>
</div>

import { Component, Input } from "@angular/core";

@Component({
  selector: "app-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.css"],
})
export class MenuItemComponent {
  @Input() icon: string = "";
  @Input() title: string = "";
  @Input() link: string = "";
  @Input() bgClass: string = "bg-soft-primary";
  @Input() textClass: string = "text-primary";
}

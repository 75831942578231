import { Component, OnInit } from "@angular/core";
import { MenuService } from "../../services/menu.service";
import { Notifications } from "src/app/models/notifications";
import * as moment from "moment";

@Component({
  selector: "app-notifications-tab",
  templateUrl: "./notifications-tab.component.html",
  styleUrls: ["./notifications-tab.component.css"],
})
export class NotificationsTabComponent implements OnInit {
  allNotifs: Notifications[] = [];
  loadAllNotifs = false;

  constructor(private menuService: MenuService) {}

  ngOnInit() {
    this.getAllNotifications();
  }

  getAllNotifications() {
    this.loadAllNotifs = true;
    this.allNotifs = [];
    this.menuService.getAllNotifs().subscribe((data: Notifications[]) => {
      if (data) {
        this.allNotifs = data;
      }
      this.loadAllNotifs = false;
    });
  }

  timeAgo(dateStr: string): string {
    const now = moment();
    const date = moment(dateStr);
    const diffInMinutes = now.diff(date, "minutes");

    if (diffInMinutes < 60) {
      return `${diffInMinutes} mins ago`;
    } else if (diffInMinutes < 1440) {
      const diffInHours = now.diff(date, "hours");
      return `${diffInHours} hours ago`;
    } else {
      return date.format("YYYY-MM-DD");
    }
  }

  markAsRead(nid: string) {
    this.menuService.readNotif(nid).subscribe(() => {
      this.getAllNotifications();
    });
  }
}

<div class="wrapper">
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body text-center">
            <h2 class="card-title">{{ greeting }}, {{ userName }}!</h2>
            <p class="card-text">What would you like to do today?</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card-box">

          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a href="#home" data-toggle="tab" aria-expanded="false" class="nav-link active">
                Recent Apps
              </a>
            </li>
            <li class="nav-item">
              <a href="#profile" data-toggle="tab" aria-expanded="true" class="nav-link ">
                My Notification
              </a>
            </li>
          </ul>
          <div class="tab-content" style="background: #e8e8e8; padding: 10px;">
            <div class="tab-pane show active" id="home">
              <div class="row">
                <div class="col-md-12">
                  <div class="menu-grid">
                    <app-menu-item *ngFor="let item of menuItems"
                      [icon]="item.icon"
                      [title]="item.title"
                      [link]="item.link"
                      [bgClass]="item.bgClass"
                      [textClass]="item.textClass"
                      [style.display]="item.visible ? 'block' : 'none'">
                    </app-menu-item>
                  </div>
                </div>

              </div>

              <div class="row">

              </div>

            </div>
            <div class="tab-pane" id="profile">
              <div class="row">
                <div class="col-md-12">
                  <app-notifications-tab></app-notifications-tab>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<app-floating-support></app-floating-support>

<header id="topnav" style="z-index: 1050;">

  <div class="navbar-custom">
    <div class="container-fluid">
      <ul class="list-unstyled topnav-menu float-right mb-0">

        <li class="dropdown notification-list">
          <a class="navbar-toggle nav-link" (click)=toggleMenu() [class.open]="mMenu">
            <div class="lines text-white">
              <span style="background: white;"></span>
              <span style="background: white;"></span>
              <span style="background: white;"></span>
            </div>
          </a>
        </li>

        <li class="dropdown notification-list" *ngIf="is_admin">
          <a routerLink="/app/admin" class="nav-link">
            <i class="bi bi-gear-fill noti-icon"></i>
          </a>
        </li>

        <li class="dropdown notification-list">
          <a class="nav-link dropdown-toggle waves-effect" data-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <i class="bi bi-bell-fill noti-icon"></i>
            <span class="badge badge-danger rounded-circle noti-icon-badge">{{notifs.length}}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-lg">

            <div class="dropdown-item noti-title">
              <h5 class="m-0">
                <span class="float-right">
                  <a (click)=markAllAsRead() style="cursor: pointer" class="text-dark">
                    <small>Mark all as read<i *ngIf="loadingNotifs" class="bi bi-arrow-repeat spin"></i></small>
                  </a>
                </span>Notification
              </h5>
            </div>

            <div class="slimscroll">
              <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let notif of notifs">
                <div class="notify-icon bg-primary">
                  <i class="bi bi-chat-square-text-fill"></i>
                </div>
                <div class="notify-details">
                  <strong class="d-block">{{notif.notificationsubject}}</strong>
                  <small class="text-muted d-block mb-1">{{ timeAgo(notif.datecreated) }}</small>
                  <p class="mb-0" style="word-wrap: break-word; overflow-wrap: break-word;">{{notif.notificationbody}}</p>
                </div>
              </a>
            </div>

            <a routerLink="/app/menu" class="dropdown-item text-center text-primary notify-item notify-all">
              View all
              <i class="bi bi-arrow-right"></i>
            </a>

          </div>
        </li>

        <li class="dropdown notification-list">
          <a class="nav-link dropdown-toggle waves-effect" data-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <i class="bi bi-grid"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-lg">

            <div class="dropdown-item noti-title">
              <h5 class="m-0">
                <span class="float-right">
                  <a href="#" class="text-dark">
                  </a>
                </span>My Apps
              </h5>
            </div>

            <div class="slimscroll">
              <a routerLink="/app/customers" *ngIf="viewCustMgt" class="dropdown-item notify-item">
                <div class="notify-icon bg-primary">
                  <i class="bi bi-people"></i>
                </div>
                <p class="notify-details">Customer Management</p>
              </a>

              <a routerLink="/app/claims" *ngIf="viewClaimsMgt" class="dropdown-item notify-item">
                <div class="notify-icon bg-soft-info">
                  <i class="bi bi-file-text text-info"></i>
                </div>
                <p class="notify-details">Claims Management</p>
              </a>

              <a routerLink="/app/providers" *ngIf="viewProviderMgt" class="dropdown-item notify-item">
                <div class="notify-icon bg-success">
                  <i class="bi bi-hospital"></i>
                </div>
                <p class="notify-details">Provider Management</p>
              </a>

              <a routerLink="/app/underwriting" *ngIf="viewUnderW" class="dropdown-item notify-item">
                <div class="notify-icon bg-soft-warning">
                  <i class="bi bi-pencil-square text-warning"></i>
                </div>
                <p class="notify-details">Underwriting</p>
              </a>

              <a routerLink="/app/policy" *ngIf="viewPolicyMgt" class="dropdown-item notify-item">
                <div class="notify-icon bg-soft-warning">
                  <i class="bi bi-pencil-square text-warning"></i>
                </div>
                <p class="notify-details">Policy Management</p>
              </a>

              <a routerLink="/app/micro" *ngIf="viewMicroIns" class="dropdown-item notify-item">
                <div class="notify-icon bg-soft-warning">
                  <i class="bi bi-file-earmark-text text-warning"></i>
                </div>
                <p class="notify-details">Micro-Insurance</p>
              </a>

              <a routerLink="/app/access" *ngIf="viewAccess" class="dropdown-item notify-item">
                <div class="notify-icon bg-soft-warning">
                  <i class="bi bi-file-earmark-text text-warning"></i>
                </div>
                <p class="notify-details">Access89</p>
              </a>

              <a routerLink="/app/mcare" *ngIf="viewMcare" class="dropdown-item notify-item">
                <div class="notify-icon bg-soft-warning">
                  <i class="bi bi-heart-pulse text-warning"></i>
                </div>
                <p class="notify-details">mCare</p>
              </a>

            </div>

          </div>
        </li>

        <li class="dropdown notification-list">
          <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect" data-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <i class="bi bi-person-circle fa-2x"></i>
            <span class="pro-user-name ml-1">
              {{urname}} <i class="bi bi-chevron-down"></i>
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right profile-dropdown ">

            <a routerLink="/app/profile" class="dropdown-item notify-item">
              <i class="bi bi-person-fill"></i>
              <span>My Account</span>
            </a>

            <div class="dropdown-divider"></div>

            <a href="javascript:void(0);" (click)="logout()" class="dropdown-item notify-item">
              <i class="bi bi-box-arrow-right"></i>
              <span>Logout</span>
            </a>

          </div>
        </li>

      </ul>

      <div class="logo-box">
        <a routerLink="/app/" class="logo text-center">
          <span class="logo-lg">
            <span class="logo-lg-text-light">Phoenix Health - Dashboard</span>
          </span>
          <span class="logo-sm">
            <span class="logo-sm-text-dark">PH</span>
          </span>
        </a>
      </div>

    </div>
  </div>

</header>

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-floating-support",
  templateUrl: "./floating-support.component.html",
  styleUrls: ["./floating-support.component.css"],
})
export class FloatingSupportComponent implements OnInit {
  ngOnInit(): void {
    // Initialidd`zation logic can be added here in the future
    this.supportPanelActive = false;
  }

  supportPanelActive = false;
  supportData = {
    name: "",
    email: "",
    message: "",
  };

  constructor() {}

  toggleSupportPanel() {
    this.supportPanelActive = !this.supportPanelActive;
  }

  onSupportSubmit() {
    console.log("Support form submitted:", this.supportData);
    this.supportData = { name: "", email: "", message: "" };
    this.supportPanelActive = false;
  }
}

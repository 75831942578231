<div class="floating-support">
  <button class="support-toggle" (click)="toggleSupportPanel()">
    <i class="bi bi-headset"></i> Need support?
  </button>
  <div class="support-panel" [class.active]="supportPanelActive">
    <div class="support-header">
      <span>Need support?</span>
      <button class="close-btn" (click)="toggleSupportPanel()">
        <i class="bi bi-x"></i>
      </button>
    </div>
    <p>Submit a support request or contact us directly.</p>
    <form (ngSubmit)="onSupportSubmit()" #supportForm="ngForm">
      <div class="form-group">
        <label for="supportName">Name</label>
        <input type="text" class="form-control" id="supportName" name="supportName" [(ngModel)]="supportData.name" required>
      </div>
      <div class="form-group">
        <label for="supportEmail">Email</label>
        <input type="email" class="form-control" id="supportEmail" name="supportEmail" [(ngModel)]="supportData.email" required>
      </div>
      <div class="form-group">
        <label for="supportMessage">Message</label>
        <textarea class="form-control" id="supportMessage" name="supportMessage" rows="3" [(ngModel)]="supportData.message" required></textarea>
      </div>
      <button type="submit" class="btn btn-primary btn-block" [disabled]="!supportForm.form.valid">Submit</button>
    </form>
    <hr>
    <p>Or contact us directly:</p>
    <div class="support-options">
      <a href="#" class="support-option" title="Email">
        <i class="bi bi-envelope"></i>
      </a>
    </div>
  </div>
</div>
